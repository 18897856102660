<template>
    <v-data-table
        :headers="headers"
        :loading="isLoading"
        :items="customers"
        :server-items-length="totalCustomers"
        :items-per-page="pagination.itemsPerPage"
        :page.sync="pagination.page"
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDescending"
        :hide-default-header="true"
        @click:row="onRowSelected"
        :mobile-breakpoint="0"
    >
        <template v-slot:item.fullName="{ item }">
            {{ item.customer.fullName | capitalize }}
        </template>

        <template v-slot:item.debt="{ item }">
            <v-chip
                label
                x-small-chip
                color="red"
                text-color="white"
            >
                {{ item.debt | toCurrency }}
            </v-chip>
        </template>
    </v-data-table>
</template>

<script>
    export default {
        components: {},

        data() {
            return {
                isLoading: false,

                pagination: {
                    page: 1,
                    itemsPerPage: 25
                },
                sorting: {
                    sortBy: 'id',
                    sortDescending: true
                },

                search: null,

                headers: [
                    { text: 'Nombre', value: 'fullName' },
                    { text: 'Deuda', value: 'debt' }
                ],

                customers: [],
                totalCustomers: 0
            };
        },

        timers: {
            searchTimer: {
                time: 700,
                autostart: false
            }
        },

        watch: {
            search() {
                this.$timer.stop('searchTimer');
                this.$timer.start('searchTimer');
            },

            pagination: {
                handler() {
                    this.fetchCustomers();
                },

                deep: true
            }
        },

        mounted() {
            this.fetchCustomers();
        },

        methods: {
            onRowSelected(debtor) {
                this.$emit('row-selected', debtor.customer);
            },

            searchTimer() {
                this.$timer.stop('searchTimer');

                this.fetchCustomers();
            },

            onCustomerSelected(customer) {
                // this.$router.push({ name: 'customerView', params: { id: customer.id } });
            },

            fetchCustomers() {
                this.isLoading = true;

                let filters = {
                    offset: this.pagination.itemsPerPage * (this.pagination.page - 1),
                    limit: this.pagination.itemsPerPage
                };

                if(this.pagination.sortBy) {
                    filters.sortBy = this.pagination.sortBy;
                    filters.sortDirection = this.pagination.descending ? 'desc' : 'asc';
                }

                if(this.search && this.search.length >= 3) {
                    filters.search = this.search;
                }

                this.$api.getDebtors(filters).then((result) => {
                    this.customers = result.records;
                    this.totalCustomers = result.count;
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
