<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="500"
    >
        <v-container>
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="active = false">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>

                        <span class="headline blue-grey--text text--darken-3">
                            Agregar Cliente
                        </span>
                    </v-row>
                </v-col>
            </v-row>

            <validation-observer ref="form" v-slot="{ valid }">
                <form>
                    <v-container>
                        <v-row>
                            <v-col cols="6">
                                <validation-provider name="customerType" v-slot="{ errors }" rules="required">
                                    <v-select
                                        :label="$t('fields.customerType')"
                                        v-model="customerType"
                                        :items="customerTypes"
                                        item-text="name"
                                        item-value="value"
                                        :error-messages="errors"
                                    />
                                </validation-provider>
                            </v-col>
                        </v-row>

                        <v-row v-if="customerType == 1">
                            <v-col cols="6">
                                <validation-provider name="firstName" v-slot="{ errors }" rules="required">
                                    <v-text-field
                                        v-model="customerFirstName"
                                        id="customerFirstName"
                                        name="customerFirstName"
                                        autocomplete="customerFirstName"
                                        x-autocompletetype="customerFirstName"
                                        :label="$t('fields.firstName')"
                                        :error-messages="errors"
                                    />
                                </validation-provider>
                            </v-col>

                            <v-col cols="6">
                                <validation-provider name="lastName" v-slot="{ errors }" rules="required">
                                    <v-text-field
                                        v-model="customerLastName"
                                        id="customerLastName"
                                        name="customerLastName"
                                        autocomplete="customerLastName"
                                        x-autocompletetype="customerLastName"
                                        :label="$t('fields.lastName')"
                                        :error-messages="errors"
                                    />
                                </validation-provider>
                            </v-col>
                        </v-row>

                        <v-row v-else>
                            <v-col cols="12">
                                <validation-provider name="companyName" v-slot="{ errors }" rules="required">
                                    <v-text-field
                                        v-model="customerCompanyName"
                                        id="customerCompanyName"
                                        name="customerCompanyName"
                                        autocomplete="customerCompanyName"
                                        x-autocompletetype="customerCompanyName"
                                        :label="$t('fields.companyName')"
                                        :error-messages="errors"
                                    />
                                </validation-provider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <validation-provider name="email" v-slot="{ errors }" rules="required|email">
                                    <v-text-field
                                        v-model="customerEmail"
                                        id="customerEmail"
                                        autocomplete="customerEmail"
                                        x-autocompletetype="customerEmail"
                                        :label="$t('fields.email')"
                                        :error-messages="errors"
                                    />
                                </validation-provider>
                            </v-col>
                        </v-row>
                    </v-container>
                </form>

                <v-container class="footer pr-6">
                    <v-row>
                        <v-col>
                            <v-btn color="primary" x-large block @click="onConfirmSelected" :loading="isSaving" :disabled="!valid">
                                Confirmar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </validation-observer>
        </v-container>
    </v-navigation-drawer>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },

        data() {
            return {
                isSaving: false,

                customerType: null,
                customerFirstName: '',
                customerLastName: '',
                customerCompanyName: '',
                customerEmail: '',

                customerTypes: [
                    { name: 'Minorista', value: 1 },
                    { name: 'Mayorista', value: 2 }
                ]
            };
        },

        props: {
            active: {
                type: Boolean,
                default: false
            }
        },

        watch: {
            active(val) {
                this.$emit('update:active', val);
            }
        },

        mounted() {
            this.customerType = this.customerTypes[0].value;
        },

        methods: {
            async onConfirmSelected() {
                let payload = {
                    isWholesaler: this.customerType === 2,
                    email: this.customerEmail
                };

                if(this.customerType === 1) {
                    payload.firstName = this.customerFirstName;
                    payload.lastName = this.customerLastName;
                }
                else {
                    payload.companyName = this.customerCompanyName;
                }

                try {
                    let result = await this.$store.dispatch('customer/create', { customer: payload });

                    this.$router.push({ name: 'customerView', params: { id: result } });
                }
                catch (err) {
                    if(err.code === 'error.request.invalid') {
                        let formErrors = {};

                        for(let errField in err.errors) {
                            formErrors[errField] = this.$t(err.errors[errField]);
                        }

                        this.$refs.form.setErrors(formErrors);
                    }
                }
                finally {
                    this.isLoading = false;
                }
            }
        }
    };
</script>
