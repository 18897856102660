<template>
    <template-layout>
        <template slot="content">
            <customer-create-drawer
                :active.sync="showCreateDrawer"
            />

            <v-container>
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Clientes</span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="elevation-0" fab x-small color="primary" @click="showCreateDrawer = !showCreateDrawer" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                        </template>
                        <span>Agregar Cliente</span>
                    </v-tooltip>
                </v-toolbar>

                <v-row>
                    <v-col cols="12" sm="4">
                        <v-card>
                            <v-card-text>
                                <h5 class="subtitle-1 font-weight-regular grey--text">Clientes nuevos</h5>
                                <h1 class="display-1 black--text">0</h1>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="4">
                        <v-card>
                            <v-card-text>
                                <h5 class="subtitle-1 font-weight-regular grey--text">Clientes activos</h5>
                                <span class="display-1 black--text">0</span>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="4">
                        <v-card>
                            <v-card-text>
                                <h5 class="subtitle-1 font-weight-regular grey--text">Total</h5>
                                <span class="display-1 black--text">{{ total }}</span>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" xl="6">
                        <v-card>
                            <v-card-title>
                                <span class="title">Clientes nuevos</span>
                            </v-card-title>

                            <v-card-text>
                                <v-data-table
                                    hide-default-header
                                    hide-default-footer
                                    :headers="headers"
                                    :loading="isLoading"
                                    :items="customers"
                                    :server-items-length="total"
                                    :items-per-page="10"
                                    :page.sync="pagination.page"
                                    :sort-by.sync="sorting.sortBy"
                                    :sort-desc.sync="sorting.sortDescending"
                                    must-sort
                                    @click:row="onCustomerSelected"
                                    :mobile-breakpoint="0"
                                >
                                    <template v-slot:item.isWholesaler="{ item }">
                                        <span v-if="item.isWholesaler">Mayorista</span>
                                        <span v-else>Minorista</span>
                                    </template>

                                    <template v-slot:item.fullName="{ item }">
                                        {{ item.fullName | capitalize }}
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" xl="6">
                        <v-card>
                            <v-card-title>
                                <span class="title">Deudores</span>
                            </v-card-title>

                            <v-card-text>
                                <debtors-list-table
                                    @row-selected="onCustomerSelected"
                                ></debtors-list-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import Vue from 'vue';
    import qs from 'qs';

    import TemplateLayout from '@/layout/TemplateLayout.vue';
    import DebtorsListTable from '@/components/Customer/DebtorsListTable';
    import CustomerCreateDrawer from './CustomerCreateDrawer';

    export default {
        components: {
            TemplateLayout,
            DebtorsListTable,
            CustomerCreateDrawer
        },

        data() {
            return {
                customers: [],
                total: 0,

                filters: {

                },

                pagination: {
                    page: 1,
                    itemsPerPage: 10
                },

                sorting: {
                    sortBy: 'id',
                    sortDescending: true
                },

                headers: [
                    { text: 'Nombre', value: 'fullName' },
                    { text: 'Tipo', value: 'isWholesaler' }
                ],

                showCreateDrawer: false
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['customer/isLoading']();
            }
        },

        watch: {
            filters: {
                handler() {
                    this.onFiltersChanged();
                },
                deep: true
            },

            pagination: {
                handler() {
                    this.filters.offset = (this.pagination.page - 1) * this.pagination.itemsPerPage;
                    this.filters.limit = this.pagination.itemsPerPage;

                    this.onFiltersChanged();
                },

                deep: true
            },

            sorting: {
                handler() {
                    this.filters.sortBy = this.sorting.sortBy;
                    this.filters.sortDirection = this.sorting.sortDescending ? 'desc' : 'asc';

                    this.onFiltersChanged();
                },
                deep: true
            },

            selectedQuickFilter(val) {
                let f = { ...this.filters, ...val };
                Vue.set(this, 'filters', f);
            }
        },

        timers: {
            fetchTimer: {
                time: 400,
                autostart: false
            }
        },

        mounted() {
            this.filters.offset = (this.pagination.page - 1) * this.pagination.itemsPerPage;
            this.filters.limit = this.pagination.itemsPerPage;
            this.filters.sortBy = this.sorting.sortBy;
            this.filters.sortDirection = this.sorting.sortDescending ? 'desc' : 'asc';
            this.defaultFilters = Object.assign({}, this.filters);

            this.updateFiltersFromQuery();
            this.onFiltersChanged();
        },

        methods: {
            onFiltersChanged() {
                this.$timer.stop('fetchTimer');
                this.$timer.start('fetchTimer');

                this.updateRouteQuery();
            },

            updateFiltersFromQuery() {
                let query = Object.keys(this.$route.query).map(e => e + '=' + this.$route.query[e]).join('&');
                this.filters = { ...this.filters, ...qs.parse(query) };

                this.pagination.itemsPerPage = this.filters.limit;
                this.pagination.page = (this.filters.offset / this.filters.limit) + 1;
                this.sorting.sortBy = this.filters.sortBy;
                this.sorting.sortDescending = this.filters.sortDirection === 'desc';
            },

            updateRouteQuery() {
                let diff = Object.keys(this.filters).reduce((map, v) => {
                    if(typeof this.filters[v] !== 'undefined' && (!(v in this.defaultFilters) || this.filters[v] !== this.defaultFilters[v])) {
                        map[v] = this.filters[v];
                    }

                    return map;
                }, {});

                let q = {};

                if(Object.keys(diff).length) {
                    q = qs.stringify(diff, { encodeValuesOnly: true })
                        .split('&')
                        .reduce((q, v) => {
                            let s = v.split('=');
                            q[s[0]] = s[1];
                            return q;
                        }, q);
                }

                this.$router.replace({ name: 'customerList', query: q }).catch(() => {});
            },

            fetchTimer() {
                this.$timer.stop('fetchTimer');
                this.fetchCustomers();
            },

            fetchCustomers() {
                let filters = {};
                filters = this.filters;

                this.$store.dispatch('customer/fetchAll', filters).then((result) => {
                    this.total = result.total;
                    this.customers = result.ids.map(id => this.$store.getters['customer/getById'](id));
                });
            },

            onCustomerSelected(customer) {
                this.$router.push({ name: 'customerView', params: { id: customer.id } });
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
